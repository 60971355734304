// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getProject = createAsyncThunk('projectData/getProject', async () => {
    try {
        let response = await axios.get('/project', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getProjectById = createAsyncThunk('projectData/getProjectById', async (Id) => {
    try {
        let response = await axios.get('/project/' + Id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getProjectOption = createAsyncThunk('projectData/getProjectOption', async () => {
    try {
        let response = await axios.get('/project?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteProject = createAsyncThunk('projectData/deleteProject', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/project/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Project deleted successfully.');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteProjectMember = createAsyncThunk('projectData/deleteProjectMember', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/project/member/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Project deleted successfully.');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createProject = createAsyncThunk('projectData/createProject', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/project', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Project added succesfully.');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createProjectMember = createAsyncThunk('projectData/createProjectMember', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/project/member', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Project added succesfully.');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createProjectManageMember = createAsyncThunk('projectData/createProjectManageMember', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/project/manage-member', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Project added succesfully.');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateProject = createAsyncThunk('projectData/updateProject', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/project/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Project updated succesfully.');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateProjectMember = createAsyncThunk('projectData/updateProjectMember', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/project/drag-member/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Project updated succesfully.');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateProjectStatus = createAsyncThunk('projectData/updateProjectStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/project/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const projectSlice = createSlice({
    name: 'projectData',
    initialState: {
        data: []
    },
    extraReducers: (builder) => {
        builder.addCase(getProject.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getProjectOption.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
        builder.addCase(getProjectById.fulfilled, (state, action) => {
            state.project = action?.payload;
            return;
        });
    }
});

export default projectSlice.reducer;
