// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getProjectRole = createAsyncThunk('ProjectRoleSliceData/getProjectRole', async () => {
    try {
        let response = await axios.get('/projectRoles', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getProjectRoleOption = createAsyncThunk('ProjectRoleSliceData/getProjectRoleOption', async () => {
    try {
        let response = await axios.get('/projectRoles?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteProjectRole = createAsyncThunk('ProjectRoleSliceData/deleteProjectRole', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/projectRoles/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Project Role deleted successfully.');
        await dispatch(getProjectRole());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createProjectRole = createAsyncThunk('ProjectRoleSliceData/createProjectRole', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/projectRoles', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Project Role added succesfully.');
        await dispatch(getProjectRole());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateProjectRole = createAsyncThunk('ProjectRoleSliceData/updateProjectRole', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/projectRoles/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Project Role updated succesfully.');
        await dispatch(getProjectRole());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateProjectRoleStatus = createAsyncThunk('ProjectRoleSliceData/updateProjectRoleStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/projectRoles/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getProjectRole());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const projectRoleSlice = createSlice({
    name: 'ProjectRoleSliceData',
    initialState: {
        data: [],
        options: []
    },
    extraReducers: (builder) => {
        builder.addCase(getProjectRole.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getProjectRoleOption.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default projectRoleSlice.reducer;
