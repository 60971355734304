// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

// ** ThemeConfig
import themeConfig from '../configs/themeConfig';

import SecureLS from 'secure-ls';

export const secureLs = new SecureLS({
    encodingType: 'aes',
    encryptionSecret: 'easymba-admin-panel',
    isCompression: false
});

const initialActiveTab = () => {
    const item = window.localStorage.getItem('activeTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.activeTab;
};
const initialRequestTab = () => {
    const item = window.localStorage.getItem('requestTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.requestTab;
};

const initialProjectTab = () => {
    const item = window.localStorage.getItem('projectTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.projectTab;
};

export const tabSlice = createSlice({
    name: 'tabSliceData',
    initialState: {
        activeTab: initialActiveTab(),
        requestTab: initialRequestTab(),
        projectTab: initialProjectTab()
    },
    reducers: {
        handleActiveTab: (state, action) => {
            state.activeTab = action.payload;
            localStorage.setItem('activeTab', JSON.stringify(action.payload));
        },
        removeTabs: (state, action) => {
            state.activeTab = '1';
            localStorage.setItem('activeTab', '1');
        },
        handleRequestTab: (state, action) => {
            state.requestTab = action.payload;
            localStorage.setItem('requestTab', JSON.stringify(action.payload));
        },
        removeRequestTabs: (state, action) => {
            state.requestTab = '1';
            localStorage.setItem('requestTab', '1');
        },
        handleProjectTab: (state, action) => {
            state.projectTab = action.payload;
            localStorage.setItem('projectTab', JSON.stringify(action.payload));
        },
        removeProjectTabs: (state, action) => {
            state.projectTab = '1';
            localStorage.setItem('projectTab', '1');
        }
    }
});

export const { handleActiveTab, removeTabs, handleRequestTab, removeRequestTabs, handleProjectTab, removeProjectTabs } = tabSlice.actions;

export default tabSlice.reducer;
